import * as React from 'react';
import styled from 'styled-components';
// import SignUpForm, {SignUpFormProps} from './SignUpForm';
// @ts-ignore
import {CenterSectionCSS, Fonts, Media, Palette} from '../../constants';
// @ts-ignore
// import PhonesContextImage from "../../images/cover-context-image.png";
// @ts-ignore
import PhonesContextImage from "../../images/app_images.png";
// // @ts-ignore
// import PhonesContextMobileImage from "../../images/cover-context-image-mobile.png";
// @ts-ignore
import PhonesContextMobileImage from "../../images/app_images.png";
import EarlyAccessBetaForm from "../../components/EarlyAccessBetaForm";
import {HeroSection} from "../../components/HeroSection";
import {TextLogo} from "../../components/TextLogo";
import {UTMs} from "../../utms";


export type CoverContentProps = {} ;

export default function ProductHero({}: CoverContentProps) {
    return (
        <HeroSection cross>
            <Container>
                <Horizontal>
                    <Left>
                        <Blurb/>
                        <EarlyAccessBetaForm utm={UTMs.subscribe_product_hero} className={'signupForm'}/>
                    </Left>
                    <Right>
                        <PhonesImage src={PhonesContextImage}/>
                    </Right>
                </Horizontal>
                <Vertical>
                    <Blurb/>
                    <PhonesImageMobile src={PhonesContextMobileImage}/>
                    <div className='p-d-flex p-flex-row p-justify-center p-align-center'>
                        <EarlyAccessBetaForm utm={UTMs.subscribe_product_hero} className={'signupForm'}/>
                    </div>
                </Vertical>
            </Container>
        </HeroSection>
    )
}

function Blurb() {
    return <>
        <Header>
            <span>Get a grip</span> on your <span>porn habit</span>
        </Header>
        <Description>
            How awesome would it be if you got support to do something other than porn right when you actually go to use porn.
            <br/>
            <br/>
            That’s exactly what <TextLogo/> does.
        </Description>
    </>;
}

const Container = styled.section`
  ${CenterSectionCSS};
  max-width: 100rem;  
  margin: 0 auto ;
  color: white ;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  padding-bottom: 4em ;
  border-bottom: 1px solid ${Palette.Yellow.hash};

  position: relative;
  border-bottom: none;  
`;
const Left = styled.div`
  display: flex;
  flex-direction: column ;
  padding-left: 2rem;
  padding-right: 2rem;
`;
const Right = styled.div`
  display: flex;
  flex-direction: column ;
  padding-left: 2rem;
  padding-right: 2rem;
`;
const PhonesImage = styled.img`
  max-width: 512px;
`;
const PhonesImageMobile = styled.img`
  display: flex ;
  width: 90%;
  margin: 0 auto;
  max-width: 512px;
  margin-top: 2rem;
  
  @media (${Media.laptop}) {
    display: none ;
    width: 90% ;  
  }

`;
const Header = styled.h1`
  width: 100%;
  font-family: ${Fonts.Poppins};
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: ${Palette.Default.color} ;
  margin-top: 1.2em;    
  font-size: 36px ;
  line-height: 54px ;
  
  @media (${Media.laptop}) {
    margin: 0 ;
    font-size: 56px !important;
    line-height: 64px;
  }
  
  span {
    white-space: nowrap;
  }
`;
const Description = styled.p`
  width: 100%;
  font-family: ${Fonts.Poppins};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${Palette.Default.color} ;
  margin-top: 2em ;
  max-width: 100% ;
  &> i {
    font-style: normal ;
    color: ${Palette.Yellow.hash};
  } ;
`;

const Horizontal = styled.div`
  display: none;
  padding-left: 4rem;
  padding-right: 4rem;
  @media (${Media.laptop}) {    
    display: flex;    
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .signupForm {
    max-width: 20em;
  }
`;
const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2em;
  
  @media (${Media.laptop}) {
    display: none;    
  }
`;
