import React, {ReactNode} from 'react';
import styled from 'styled-components';
// @ts-ignore
import LittleArrow from '../images/littlearrow.svg';
import classNames from "classnames";
import {Colors, Fonts, Media} from "../constants";

export type HighlightTipProps = {
    className?: string;
    location: 'left' | 'right' | 'bottom' | 'top';
    position: number;
    rotation: number;
    children: ReactNode;
};
const ClassName = 'HighlightTip';
export function HighlightTip({className, children, location, position, rotation}: HighlightTipProps) {
    return <Container className={classNames(ClassName, className)} {...{location, position, rotation}}>
        <img src={LittleArrow} className={`${ClassName}-littleArrow littleArrow`} />
        <div className={`${ClassName}-content`}>
            {children}
        </div>
    </Container>;
}



type ContainerProps = Pick<HighlightTipProps, 'location' | 'position' | 'rotation'>;
const Container = styled.div`
  position: relative;
  color: ${Colors.Yellow};
  font-family: ${Fonts.IndieFlower};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px;  
  font-size: 10pt;
  
  @media (${Media.tablet}) {
     font-size: 1.2rem;
  }
      
  
  .${ClassName}-littleArrow {
    border: 1px solid ${({location}) => location};
    height: 24px;
    width: 24px;
    position: absolute;    
    ${({location}: ContainerProps) => location}: -1em;
    ${({location, position}: ContainerProps) => `${ArrowPositionsByLocation[location]}: calc(((24px + 100%) * ${position/100}) - 24px)`};
    
    transform: rotate(${({rotation}: ContainerProps) => rotation}deg)         
  }
  .${ClassName}-content {    
    text-align: center;
    margin-top: 6px;
  }
`;


const ArrowPositionsByLocation = {
    top: 'left',
    right: 'top',
    bottom: 'left',
    left: 'top'
};
