import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {IntrinsicElementProps} from "../../utils";
import {TwoToneText} from "../../components/TwoToneText";
import {HighlightTip, HighlightTipProps} from "../../components/HighlightTip";
// @ts-ignore
import SequenceArrow1 from '../../images/sequence_arrow_1.svg';
// @ts-ignore
import SequenceArrow2 from '../../images/sequence_arrow_2.svg';
// @ts-ignore
import MobileChoiceArrow from '../../images/mobile_choice_arrow.svg';
// @ts-ignore
import FuzzyPornBackground from '../../images/DoBuddy_FuzzyPornBackground.png';
import {TextLogo} from "../../components/TextLogo";
import {Colors, Fonts, Media} from "../../constants";
// @ts-ignore
import Image1 from "../../images/wpic1.png";
// @ts-ignore
import Image2 from "../../images/wpic2.png";
// @ts-ignore
import Image3 from "../../images/wpic3.png";
// @ts-ignore
import Image4 from "../../images/wpic4.png";


export type ImagineProps = {} & IntrinsicElementProps;

export function Imagine({...props}: ImagineProps) {
    return <Container {...props}>
        <h2><TwoToneText w1={'Imagine'} w2={'This'} reverse/></h2>
        <Block lead={1} text={<>Open a porn site</>} tip={{location: 'top', position: 60, children: <>The bit you're struggling with</>, rotation: 120}}/>
        <Block lead={2} text={<><TextLogo/> checks if you're sure...</>} tip={{location: 'top', position: 70, children: <>The technical wizardry bit</>, rotation: 90}}/>
        <Block lead={3} text={<>...and suggests alternative</>} tip={{location: 'top', position: 40, children: <>The psychology bit</>, rotation: 160}}/>
        <div className={'youchoose'}>You choose</div>
        <div className={'choices'}>
            <div className={'watchPorn'}>
                <div className={'text'}><TwoToneText w1={<>Watch porn<br/></>} w2={'this time'}/></div>
                <div className={'image'}>
                    <img src={FuzzyPornBackground}/>
                </div>
                <HighlightTip className='tip' location={'top'} position={50} rotation={135}> The bit where life is just a little too hard and you really don’t need anyone busting your balls... not yourself, and definitely not some opinionated app</HighlightTip>
            </div>
            <div className={'or'}>OR!</div>
            <div className={'doSomething'}>
                <div className={'text'}><TwoToneText w1={<>Do something<br/></>} w2={'positive and healthy'} reverse/></div>
                <HighlightTip className='tip' location={'top'} position={46} rotation={140}>The bit where you feel awesome.<br />And yes, we help you with this.</HighlightTip>
                <div className={'carousel'}>
                    <img src={Image1} />
                    <img src={Image2} />
                    <img src={Image3} />
                    <img src={Image4} />
                </div>
            </div>
            <div className={'choiceArrow'}/>
        </div>


    </Container>;
}

type BlockProps = {
    lead: ReactNode;
    text: ReactNode;
    tip: HighlightTipProps;


};

function Block({lead, text, tip}: BlockProps) {
    return <div className={'block'}>
        <div>
            <div className={'blockContent'}>
                <div className={'lead'}>{lead}.</div>
                <div className={'text'}>{text}</div>
            </div>
            <HighlightTip {...tip} className={'tip'}/>
            <div className={'arrow'}/>
        </div>
    </div>;
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4em;
  margin-bottom: 8em;
  padding-left: 2em;
  padding-right: 2em;

 .block {
    position: relative;
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
  }

  .block {
    position: relative;
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;

    .arrow {
      position: absolute;
      background: transparent url(${SequenceArrow2}) no-repeat center center;
      background-size: contain;
      height: 2.5rem;
      width: 6rem;
      @media (${Media.laptop}) {
        background-image: url(${SequenceArrow1});
      }
    }

    .tip {
      width: 60%;

      @media (${Media.laptop}) {
        width: unset;
      }
    }

    &:nth-of-type(1) {
      margin-top: 2rem;
      left: -10%;

      .arrow {
         top: 4rem;
         right: -10%;
         transform: rotate(-10deg);
      }
      @media (${Media.laptop}) {
        left: unset;
        right: 20rem;
        .arrow {
          top: 3rem;
          left: 19rem;
          transform: rotate(0deg);
        }
      }
    }
    &:nth-of-type(2) {
      margin-top: 1rem;
      left: 10%;
      .tip {
        right: -30%;
      }
      .arrow {
        left: -10%;
        bottom: 2rem;
        transform: scale(-1, 1);
      }

      @media (${Media.laptop}) {
        left: unset;
        .tip {
          right: unset;
        }
        .arrow {
          position: absolute;
          top: 3rem;
          left: 19rem;
          transform: unset;
        }
      }
    }
    &:nth-of-type(3) {
      margin-top: 1rem;
      .arrow {
        bottom: 0;
        right: 0;
        transform: rotate(30deg);
      }

      @media (${Media.laptop}) {
        left: 20rem;
        .arrow {
          background-image: url(${SequenceArrow1});
          position: absolute;
          top: 8rem;
          left: -5rem;
          bottom: unset;
          right: unset;
          transform: rotate(130deg);
        }
      }
    }

    .blockContent {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .lead {
      color: ${Colors.Yellow};
      font-size: 2rem;
      margin-right: 1rem;
      flex: 0 0 auto
    }
    .text {
      flex: 1 1 auto;
      max-width: 14rem;
      text-align: center;
      line-height: 1.2em;
    }
    .tip {

    }
  }



 .youchoose {
    width: 30rem;
    height: 30rem;
    display: flex;
    margin-top: -4rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    background: radial-gradient(50% 50% at 50% 50%, rgba(196, 196, 196, 0.26) 0%, rgba(196, 196, 196, 0) 100%);
    font-size: 2.4rem;
    font-weight: bold;
    &::before {
      content: '?';
      font-family: ${Fonts.Inter};
      font-size: 12rem;
      margin-bottom: 6rem;
      color: ${Colors.Yellow};
      font-weight: 600;
    }

    @media (${Media.laptop}) {
      margin-top: -8rem;
    }
  }
  .choices {
    position: relative;
    @media (${Media.laptop}) {
      //display: none;
    }

    .choiceArrow {
      position: absolute;
      background: transparent url(${MobileChoiceArrow}) no-repeat center center;
      background-size: contain;
      width: 100%;
      margin-left: 2rem;
      height: 30rem;

      top: -8rem;
    }

    .watchPorn {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      grid-template-areas:
        "text image"
        "tip image"
      ;
      max-width: 48rem;


      .text {
        grid-area: text;
        font-size: 2rem;
        line-height: 1.2em;
        justify-self: end;
        margin-right: 15%;
         @media (${Media.laptop}) {
          justify-self: center;
        }

      }
      .image {
        grid-area: image;
        position: relative;
        height: 100%;
        width: 50%;
        img {
          width: 20rem;
          position: absolute;
        }
      }

      .tip {
        grid-area: tip;
        margin: 1rem 0.5rem 2rem 0.5rem;
        @media (${Media.laptop}) {
          justify-self: left;
          margin: 2rem;          
        }
      }

    }
    
    .or {
      font-size: 3rem;
      text-align: center;
      margin: 2rem;
      @media (${Media.laptop}) {
         margin-top: 4rem;
         margin-bottom: 4rem;
        }
    }
    
    .doSomething {
      padding-top: 2rem;
      text-align: center;
      
      .text {
        font-size: 2rem;
        line-height: 1.2em;      
      }
      
    }
    
    .carousel {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      grid-column-gap: 0.2rem;
      grid-row-gap: 0.2rem;
      img {
        object-fit: cover;
        width: 100%;
        max-height: 100%;
      }
    
      @media (${Media.laptop}) {
        max-width: 52rem; 
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-column-gap: 1rem;           
      }     
    }
  }
`;
