import "primereact/resources/themes/saga-orange/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';
import * as React from "react"
import {Layout} from "../containers/Layout";
import ProductHero from "../sections/product/ProductHero";
/*import {Sections} from "../sections";*/
// @ts-ignore
import {PageProps} from "gatsby";
import {Recognise} from "../sections/product/Recognise";
import {Imagine} from "../sections/product/Imagine";
import {TheApp} from "../sections/product/TheApp";

export default function Index(props: PageProps) {
    return (
        <Layout {...props}>
            <div id={"home"}/>
            <ProductHero />
            <Imagine/>
            <Recognise />
            <TheApp />
            {/* Sections.map(({Component, ...props}, i) => (<Component key={i} {...props} variant={i % 2 ? 'right' : 'left'}/>)) */}
        </Layout>
    )
}
