import React, {Children, ReactNode} from 'react';
import styled from 'styled-components';
import {IntrinsicElementProps} from "../utils";
// @ts-ignore
import Tick from '../images/tick.svg';


export type TickListProps = {children: ReactNode | ReactNode[]} & IntrinsicElementProps;
export function TickList({children, ...props}: TickListProps) {
    return <Container {...props}>
        {Children.map(children, child => <li><img src={Tick}/><div className={'li-content'}>{child}</div></li>)}
    </Container>;
}

const Container = styled.ul`
  li {    
    list-style-image: none;
    display: grid;
    grid-template-columns: 1rem 1fr;
    grid-template-rows: auto;
    grid-column-gap: 1rem;
    margin-bottom: 1rem;
    
    &>img {
      max-height: 100%;
      max-width: 100%;
    }    
  } 
  
`;
