import React from 'react';
import styled from 'styled-components';
import {IntrinsicElementProps} from "../../utils";
// @ts-ignore
import backgroundLaptop from '../../images/Background3.svg';
// @ts-ignore
import backgroundMobile from '../../images/Background3_mobile.svg';
// @ts-ignore
import AndroidMobile from '../../images/android_mobile.svg';
// @ts-ignore
import AndroidLaptop from '../../images/android_laptop.svg';
import {TwoToneText} from "../../components/TwoToneText";
import {Fonts, Media, Palette} from "../../constants";
import {TextLogo} from "../../components/TextLogo";
import EarlyAccessBetaForm from "../../components/EarlyAccessBetaForm";
import {TickList} from "../../components/TickList";
import {UTMs} from "../../utms";


export type TheAppProps = {} & IntrinsicElementProps;

export function TheApp({...props}: TheAppProps) {
    return <Container {...props}>
        <h2><TwoToneText w1={'The'} w2={'App'}/></h2>
        <Blurb>
            <p><TextLogo/> doesn't just block porn.</p>
            <p>We use psychologically savvy engagements to offer alternatives and strengthen your inate ability to make healthy choices.</p>
        </Blurb>
        <EarlyAccessBetaForm utm={UTMs.subscribe_product_theapp} className={'signupform'}/>
        <Bullets>
            <TickList className={'bulletslist'}>
                <div>No in-app login required</div>
            </TickList>
        </Bullets>
    </Container>;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: 1px solid ${Palette.withOpacity(Palette.Yellow, 0.4)};
  padding-bottom: 2rem;
  
  background: url(${backgroundMobile}) top center;
  background-size: cover;
  @media (${Media.laptop}) {
    padding-top: 4rem;
    background: url(${backgroundLaptop}) top center;      
    background-size: cover;
  }
  
  .signupform {
    padding: 0 2rem;
  }
`;

const Blurb = styled.div`
  font-family: ${Fonts.Poppins};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${Palette.Default.color} ;
  text-align: center;
  padding: 0 1rem;
  max-width: 40rem;  
  &> i {
    font-style: normal ;
    color: ${Palette.Yellow.hash};
  } ;
  
  @media (${Media.laptop}) {
    padding-bottom: 1rem;
  }
`;


const Availability = styled.div`
    font-family: ${Fonts.Poppins};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;   
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    padding-bottom: 2rem;
    
    .options {
      display: flex;
      align-items: center;
      justify-content: center;
      
      img.horizontal {
        display: none;      
      }     
      @media (${Media.laptop}) {
        img.vertical {
          display: none;        
        }      
        img.horizontal {
          display: block;        
        }      
      }
    }   
    
`;

const Bullets = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  @media (${Media.laptop}) {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }  
  .bulletslist {
    display: flex;
    flex-direction: row;
    margin-left: 0;
    padding-left: 0;
          
    li {
      margin-left: 2rem;
      
      &:first-of-type {
        margin-left: 0;
      }
    }
  }        
`;
